import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/", // 首页
    name: "home",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/home.vue"),
  },{
    path: "/index", // 首页
    name: "index",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/index.vue"),
  },
  {
    path: "/search", // 首页搜索
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/search.vue"),
  },
  {
    path: "/login", // 登录
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/workSearch", // 职位搜索
    name: "workSearch",
    component: () =>
      import(/* webpackChunkName: "workSearch" */ "../views/workSearch.vue"),
  },
  {
    path: "/campusRecruitment", // 校园招聘
    name: "campusRecruitment",
    component: () =>
      import(
        /* webpackChunkName: "campusRecruitment" */ "../views/campusRecruitment.vue"
      ),
  },
  {
    path: "/overseas", // 海外专场
    name: "overseas",
    component: () =>
      import(/* webpackChunkName: "overseas" */ "../views/overseas.vue"),
  },
  {
    path: "/overseass", // 海外专场--复制
    name: "overseass",
    component: () =>
      import(/* webpackChunkName: "overseas" */ "../views/overseass.vue"),
  },
  {
    path: "/brand", // 品牌介绍
    name: "brand",
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/brand.vue"),
  },
  {
    path: "/company", // 公司介绍
    name: "company",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/company.vue"),
  },
  {
    path: "/position", // 职位详情
    name: "position",
    component: () =>
      import(/* webpackChunkName: "position" */ "../views/position.vue"),
  },
  {
    path: "/about", // 关于我们
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/contact", // 联系我们
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact.vue"),
  },
  {
    path: "/my_deliver", // 我的投递
    name: "my_deliver",
    component: () =>
      import(/* webpackChunkName: "my_deliver" */ "../views/my_deliver.vue"),
  },
  {
    path: "/notes", // 在线简历
    name: "notes",
    component: () =>
      import(/* webpackChunkName: "notes" */ "../views/notes.vue"),
  },
  {
    path: "/my_notes", // 简历中心
    name: "my_notes",
    component: () =>
      import(/* webpackChunkName: "my_notes" */ "../views/my_notes.vue"),
  },
  {
    path: "/settingUser", // 个人中心
    name: "settingUser",
    component: () =>
      import(/* webpackChunkName: "settingUser" */ "../views/settingUser.vue"),
  },
  {
    path: "/settingUsers", // 个人中心
    name: "settingUsers",
    component: () =>
      import(/* webpackChunkName: "settingUser" */ "../views/settingUsers.vue"),
  },
  {
    path: "/zhengfu", // 政府引才项目
    name: "zhengfu",
    component: () =>
      import(/* webpackChunkName: "zhengfu" */ "../views/zhengfu.vue"),
  },
  {
    path: "/zhengfu1", // 政府引才项目1
    name: "zhengfu1",
    component: () =>
      import(/* webpackChunkName: "zhengfu1" */ "../views/zhengfu1.vue"),
  },
  {
    path: "/zhengfu2", // 政府引才项目1
    name: "zhengfu2",
    component: () =>
      import(/* webpackChunkName: "zhengfu2" */ "../views/zhengfu2.vue"),
  },
  {
    path: "/agreement", // 所有协议页面
    name: "agreement",
    component: () =>
      import(/* webpackChunkName: "agreement" */ "../views/agreement.vue"),
  },
  {
    path: "/message", // 消息
    name: "message",
    component: () =>
      import(/* webpackChunkName: "message" */ "../views/message.vue"),
  },
  {
    path: "/news", // 新闻
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/PhaseII/news.vue"),
  },
  {
    path: "/newss", // 新闻
    name: "newss",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/news.vue"),
  },
  {
    path: "/news1", // 新闻
    name: "news1",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/PhaseII/news1.vue"),
  },
  {
    path: "/news2", // 新闻
    name: "news2",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/PhaseII/news2.vue"),
  },
  {
    path: "/newsinfo", // 新闻详情
    name: "newsinfo",
    component: () =>
      import(/* webpackChunkName: "newsinfo" */ "../views/PhaseII/newsinfo.vue"),
  },
  {
    path: "/newsinfos", // 新闻详情
    name: "newsinfos",
    component: () =>
      import(/* webpackChunkName: "newsinfo" */ "../views/newsinfo.vue"),
  },
  {
    path: "/personneltraining", // 人才培养
    name: "personneltraining",
    component: () =>
      import(/* webpackChunkName: "personneltraining" */ "../views/PhaseII/personneltraining.vue"),
  },
  {
    path: "/work", // 办事窗口
    name: "work",
    component: () =>
      import(/* webpackChunkName: "work" */ "../views/PhaseII/work.vue"),
  },
  {
    path: "/application", // 报名系统
    name: "application",
    component: () =>
      import(/* webpackChunkName: "application" */ "../views/PhaseII/application.vue"),
  },
  {
    path: "/application1list", // 入学报名
    name: "application1list",
    component: () =>
      import(/* webpackChunkName: "application1list" */ "../views/PhaseII/application1list.vue"),
  },
  {
    path: "/application1", // 入学报名
    name: "application1",
    component: () =>
      import(/* webpackChunkName: "application1" */ "../views/PhaseII/application1.vue"),
  },
  {
    path: "/review", // 职称评审
    name: "review",
    component: () =>
      import(/* webpackChunkName: "review" */ "../views/PhaseII/review.vue"),
  },
  {
    path: "/review1", // 职称评审--详情
    name: "review1",
    component: () =>
      import(/* webpackChunkName: "review1" */ "../views/PhaseII/review1.vue"),
  },
  {
    path: "/review2", // 职称评审--申报提交
    name: "review2",
    component: () =>
      import(/* webpackChunkName: "review2" */ "../views/PhaseII/review2.vue"),
  },
  {
    path: "/download", // 办事窗口--下载专区
    name: "download",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/PhaseII/download.vue"),
  },
  {
    path: "/service", // 人才服务
    name: "service",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/PhaseII/service.vue"),
  },
  {
    path: "/service1", // 人才服务-人才项目信息
    name: "service1",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/PhaseII/service1.vue"),
  },
  {
    path: "/service1info", // 人才服务-人才项目信息--详情
    name: "service1info",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/PhaseII/service1info.vue"),
  },
  {
    path: "/continuingeducation", // 继续教育
    name: "continuingeducation",
    component: () =>
      import(/* webpackChunkName: "continuingeducation" */ "../views/PhaseII/continuingeducation.vue"),
  },
  {
    path: "/continuingeducation1", // 继续教育2
    name: "continuingeducation1",
    component: () =>
      import(/* webpackChunkName: "continuingeducation1" */ "../views/PhaseII/continuingeducation1.vue"),
  },
  {
    path: "/threeterms", // 三项顶岗
    name: "threeterms",
    component: () =>
      import(/* webpackChunkName: "threeterms" */ "../views/PhaseII/threeterms.vue"),
  },
  {
    path: "/guide", // 指南
    name: "guide",
    component: () =>
      import(/* webpackChunkName: "threeterms" */ "../views/PhaseII/guide.vue"),
  },
  {
    path: "/guideinfo", // 指南详情
    name: "guideinfo",
    component: () =>
      import(/* webpackChunkName: "threeterms" */ "../views/PhaseII/guideinfo.vue"),
  },
  {
    path: "/institution", // 服务机构
    name: "institution",
    component: () =>
      import(/* webpackChunkName: "threeterms" */ "../views/PhaseII/institution.vue"),
  },
  {
    path: "/institutioninfo", // 服务机构详情
    name: "institutioninfo",
    component: () =>
      import(/* webpackChunkName: "threeterms" */ "../views/PhaseII/institutioninfo.vue"),
  },  {
    path: "/Newtdxl", // 新同等学力
    name: "Newtdxl",
    component: () =>
      import(/* webpackChunkName: "threeterms" */ "../views/Newtdxl.vue"),
  },
];





const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
