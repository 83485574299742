import axios from "axios";  
import { Message, Loading } from "element-ui";  
import store from "@/store";  
import router from "@/router";  
import { MessageBox } from 'element-ui';  

let loading = null;  

const service = axios.create({  
  baseURL: "https://www.sxjgrcw.cn",  
  timeout: 10000 // 设置请求超时时间  
});  

// request interceptor  
service.interceptors.request.use(  
  config => {  
    let token = localStorage.getItem('token');  
    if (token) {  
      config.headers.Authorization = `Bearer ${token}`;  
    }  
    loading = Loading.service({  
      lock: false,  
      text: "Loading",  
      spinner: "el-icon-loading",  
      background: "rgba(0, 0, 0, 0.7)"  
    });  
    return config;  
  },  
  error => {  
    console.log(error); // for debug  
    return Promise.reject(error);  
  }  
);  

// response interceptor  
service.interceptors.response.use(  
  response => {  
    if (loading) loading.close();  
    const res = response.data;  
    if (res.code === 0) {  
      return res;  
    } else if (res.code === 401) {  
      Message({  
        message: "登录过期，请重新登录！",  
        type: "error"  
      });  

      let qrCode = localStorage.getItem('qrCode');  
      localStorage.clear();  
      localStorage.setItem('redirectAfterLogin', router.currentRoute.path);  
      localStorage.setItem("qrCode", qrCode);  
      setTimeout(() => {  
        router.replace("/login");  
      }, 800);  
      return res;  
    } else {  
      showMaintenanceMessage();  
      return res;  
    }  
  },  
  error => {  
    if (loading) loading.close();  
    const message = error.response ? error.response.data.message : error.message;  
    Message({  
      message: message,  
      type: "error"  
    });  
    return Promise.reject(error);  
  }  
);  
// 显示维护提示框的函数  
function showMaintenanceMessage() {  
  MessageBox.alert('系统维护中，请稍后再试。', '维护提示', {  
    confirmButtonText: '好的',  
    callback: () => {  
      // 您可以在这里执行其他操作，例如跳转到首页  
      console.log('用户点击了确认按钮');  
      // 如果需要跳转页面，可以使用下面的代码：  
      // window.location.href = '/';  
    },  
  }); 
}  

// HTTP 方法封装  
const get = (url, params) =>  
  service.get(url, { params });  

const post = (url, data) =>  
  service.post(url, data);  

const put = (url, data) =>  
  service.put(url, data);  

const del = (url, data) =>  
  service.delete(url, { data });  

export { get, post, put, del };  
export default service;