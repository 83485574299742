import Vue from 'vue'
import Vuex from 'vuex'
import { Message } from "element-ui";
import { loadBMap } from "@/common/map";
// import { getToken, setToken, removeToken } from "@/utils/auth";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // token: getToken(),
    point: {
      province: "",
      city: "",
      latitude: "",
      longitude: ""
    }
  },
  getters: {
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      // state.token = token;
    },
    changePoint(state, point) {
      state.point.province = point.province;
      state.point.city = point.city;
      state.point.latitude = point.latitude;
      state.point.longitude = point.longitude;
    },
  },
  actions: {
    // 如有缓存直接赋值vuex 不去请求接口
    loadLocal({ commit }) {
      // const point = window.localStorage.getItem("point");
      // if (point) {
      //   commit("changePoint", JSON.parse(point));
      // }
    },
    getArea({ commit }) {
      let that = this;
      //定义回调函数
      window.initBaiduMapScript = () => {
        that.BMap = window.BMap;
        var geolocation = new that.BMap.Geolocation();
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == 0) {
              let pointInfo = {
                province: r.address.province,
                city: r.address.city,
                latitude: r.latitude,
                longitude: r.longitude
              };
              //成功获取位置信息
              commit("changePoint", pointInfo);
              window.localStorage.setItem("point", JSON.stringify(pointInfo));
            } else {
              //无法获取到位置信息
              Message.error('无法获取到位置信息');
            }
          },
          {
            enableHighAccuracy: true
          }
        );
      };
      //调用脚本获取位置信息
      loadBMap("initBaiduMapScript");
    },
  },
  modules: {
  }
})
