import request from "@/utils/request";

// -------------------------------------------------------------- 接口区 -------------------------------------------------------------- //

// 短信验证码注册-登录
export function smsLogin(data) {
  return request({
    url: "/admin-api/web/s-admin/registerLogin",
    method: "POST",
    data,
  });
}


//改变im注册状态
export function changim(params) {			
	// console.log(data,444444444444,data['signature']);
	return request({
// ?id=${data.id}
		url: `/admin-api/web/s-admin/updates`,
		method: 'get',
		// data,
		params,
		noLoad: true
		// ,aiyoue:true
	})
}
//获取usersig  --- im
export function getusersig(params) {			
	// console.log(data,444444444444,data['signature']);
	return request({

		url: '/admin-api/web/s-admin/gets',
		method: 'get',
		params,
		noLoad: true
		// ,aiyouee:true
	})
}


// 发送手机验证码
export function sendSmsCode(data) {
  return request({
    url: "/admin-api/system/auth/send-sms-code",
    method: "POST",
    data,
    headers: {
      'tenant-id': "1"
    },
  });
}



// 获取用户信息
export function getshield(params) {
  return request({
    url: "/admin-api/web/s-admin/get",
    method: "GET",
    params,
  });
}



// 热门职位
export function hots(params) {
  return request({
    url: "/admin-api/web/position-log/hots",
    method: "GET",
    params,
  });
}

// 首页热招职位
export function search(params) {
  return request({
    url: "/admin-api/web/position/pages",
    method: "GET",
    params,
  });
}

// 职位搜索页面-----职位列表
export function getsearch(params) {
  return request({
    url: "/admin-api/web/position/search",
    method: "GET",
    params,
  });
}


// 行业分类
export function geta(params) {
  return request({
    url: "/admin-api/web/enter/geta",
    method: "GET",
    params,
  });
}

// 首页大轮播图
export function guanggao(params) {
  return request({
    url: "/admin-api/web/enter/guanggao",
    method: "GET",
    params,
  });
}

// 企业推荐
export function recommend(params) {
  return request({
    url: "/admin-api/web/position/recommend",
    method: "GET",
    params,
  });
}

// 我的投递简历  被查看  收藏  邀面试  不合适
export function viewtest(params) {
  return request({
    url: "/admin-api/web/position-log/viewtest",
    method: "GET",
    params,
  });
}


// 校园招聘
export function xiaoyuan(params) {
  return request({
    url: "/admin-api/web/enter/xiaoyuan",
    method: "GET",
    params,
  });
}

// 职位详情
export function getPositionInfo(params) {
  return request({
    url: "/admin-api/web/position/getPositionInfo",
    method: "GET",
    params,
  });
}

// 品牌
export function getbrand(params) {
  return request({
    url: "/admin-api/web/enter/brand",
    method: "GET",
    params,
  });
}



// 政府机关在线数量以及岗位数量
export function getnum(params) {
  return request({
    url: "/admin-api/web/enter/getnum",
    method: "GET",
    params,
  });
}

// 政府职位搜索---政府招聘文章  人才招聘
export function searchs(params) {
  return request({
    url: "/admin-api/web/position/searchs",
    method: "GET",
    params,
  });
}

// 政府更多企业
export function searchss(params) {
  return request({
    url: "/admin-api/web/position/searchss",
    method: "GET",
    params,
  });
}



// 获得海外校园热招
export function overseas(params) {
  return request({
    url: "/admin-api/web/enter/overseas",
    method: "GET",
    params,
  });
}
// 品牌--海外
export function brandss(params) {
  return request({
    url: "/admin-api/web/enter/brandss",
    method: "GET",
    params,
  });
}




// 公司详情
export function getget(params) {
  return request({
    url: "/admin-api/web/enter/get",
    method: "GET",
    params,
  });
}

// 公司发布的职位
export function positionNum(params) {
  return request({
    url: "/admin-api/web/position/positionNum",
    method: "GET",
    params,
  });
}

export function positionNums(params) {
  return request({
    url: "/admin-api/web/position/positionNums",
    method: "GET",
    params,
  });
}



// 职位收藏
export function create(data) {
  return request({
    url: "/admin-api/web/position-log/create",
    method: "POST",
    data,
  });
}

// 取消收藏
export function update(data) {
  return request({
    url: "/admin-api/web/position-log/update",
    method: "PUT",
    data,
  });
}



// 我的简历列表
export function gets(params) {
  return request({
    url: "/admin-api/web/seekers/gets",
    method: "GET",
    params,
  });
}



// 关于我们
export function AboutUs(params) {
  return request({
    url: "/admin-api/web/about/list",
    method: "GET",
    params,
  });
}


// 投递简历
export function sendresume(data) {
  return request({
    url: "/admin-api/web/position-log/send-resume",
    method: "POST",
    data,
  });
}

// 新增简历
export function getcreate(data) {
  return request({
    url: "/admin-api/web/seekers/create",
    method: "POST",
    data,
  });
}

// 上传
export function upload(data) {
  return request({
    url: "/admin-api/infra/file/upload",
    method: "POST",
    data,
  });
}

// 求职者修改简历
export function updateSeekers(data) {
  return request({
    url: "/admin-api/web/seekers/updateSeekers",
    method: "POST",
    data,
  });
}

// 求职者简历详情
export function getResExp(params) {
  return request({
    url: "/admin-api/web/seekers/getResExp",
    method: "GET",
    params,
  });
}

// 获取学历字典
export function getpage(params) {
  return request({
    url: "/admin-api/system/dict-data/page",
    method: "GET",
    params,
  });
}

// 删除教育经历
export function getdelete(params) {
  return request({
    url: "/admin-api/web/education-exper/delete",
    method: "DELETE",
    params,
  });
}

// 删除工作经历
export function getdeletes(params) {
  return request({
    url: "/admin-api/web/work-exper/delete",
    method: "DELETE",
    params,
  });
}

// 删除项目经历
export function getdeletess(params) {
  return request({
    url: "/admin-api/web/project-exper/delete",
    method: "DELETE",
    params,
  });
}

export function getdeletessss(params) {
  return request({
    url: "/admin-api/web/production/delete",
    method: "DELETE",
    params,
  });
}


export function getdeletesss(params) {
  return request({
    url: "/admin-api/web/qualification/delete",
    method: "DELETE",
    params,
  });
}

// 账号设置-修改
export function updateid(data) {
  return request({
    url: "/admin-api/web/s-admin/updateid",
    method: "PUT",
    data,
  });
}

// 评论区新增
export function Plcreate(data) {
  return request({
    url: "/admin-api/web/comment/create",
    method: "POST",
    data,
  });
}

// 评论区删除
export function Pldelete(params) {
  return request({
    url: "/admin-api/web/comment/delete",
    method: "DELETE",
    params,
  });
}

// 评论列表
export function Plpage(params) {
  return request({
    url: "/admin-api/web/comment/page",
    method: "GET",
    params,
  });
}


// 厅级部门列表
export function mbpage(params) {
  return request({
    url: "/admin-api/web/department/page",
    method: "GET",
    params,
  });
}

// 厅级部门下的公司列表
export function mbsearchss(params) {
  return request({
    url: "/admin-api/web/position/searchss",
    method: "GET",
    params,
  });
}


// 行业分类
export function hyflpage(params) {
  return request({
    url: "/admin-api/web/zidian/page",
    method: "GET",
    params,
  });
}

// 人才招聘周直播活动
export function zhibopage(params) {
  return request({
    url: "/admin-api/web/zhibo/page",
    method: "GET",
    params,
  });
}


// 微信扫码
export function tokena(params) {
  return request({
    url: "admin-api/web/s-admin/tokena",
    method: "GET",
    params,
  });
}

// export function erweima(params) {
//   return request({
//     url: "admin-api/web/s-admin/erweima",
//     method: "GET",
//     params,
//   });
// }

// 谁看过我的简历
export function shui(params) {
  return request({
    url: "admin-api/web/position-log/shui",
    method: "GET",
    params,
  });
}


export function jldelete(params) {
  return request({
    url: "admin-api/web/seekers/delete",
    method: "DELETE",
    params,
  });
}




// 屏蔽企业
export function pbupdate(data) {
  return request({
    url: "/admin-api/web/s-admin/update",
    method: "PUT",
    data,
  });
}


// 公司列表
export function gslist(params) {
  return request({
    url: "admin-api/web/enter/pages",
    method: "GET",
    params,
  });
}


// 获取面试邀请信息
export function msyqinfo(params) {
  return request({
    url: "admin-api/web/interview/get",
    method: "GET",
    params,
  });
}

// 同意，拒接面试邀请
export function tyjjupdatea(data) {
  return request({
    url: "admin-api/web/position-log/updatea",
    method: "PUT",
    data,
  });
}


// 用户接收的信息
export function jsxxget(params) {
  return request({
    url: "admin-api/web/message/get",
    method: "GET",
    params,
  });
}

// 修改接收消息的状态  查看
export function updateState(data) {
  return request({
    url: "admin-api/web/message/updateState",
    method: "PUT",
    data,
  });
}


export function ymswcz(params) {
  return request({
    url: "admin-api/web/position-log/gets",
    method: "GET",
    params,
  });
}


//  所属行业-分页
export function zidianPages(params) {
  return request({
    url: "admin-api/web/zidian/pages",
    method: "GET",
    params,
  });
}


export function erweima(params) {
  return request({
    url: "admin-api/web/s-admin/erweima",
    method: "GET",
    params,
  });
}

export function yanzheng(params) {
  return request({
    url: "admin-api/web/s-admin/yanzheng",
    method: "GET",
    params,
  });
}


export function ycupdate(data) {
  return request({
    url: "admin-api/web/seekers/update",
    method: "PUT",
    data,
  });
}

// 公司列表
export function searchbb(params) {
  return request({
    url: "/admin-api/web/position/searchbb",
    method: "GET",
    params,
  });
}

// 同等学历通知公告---ok
export function Hotwointroductionrds(params) {
  return request({
    url: "/admin-api/web/post-introduction/pages",
    method: "GET",
    params,
  });
}

// ·········································二期新增功能

// ··············政策新闻(热门资讯、军工动态、人才政策)
// 热门资讯、军工动态---ok
export function Hotword(params) {
  return request({
    url: "/admin-api/web/news/page",
    method: "GET",
    params,
  });
}
// 热门资讯详情、军工动态详情---ok
export function Hotwordinfo(params) {
  return request({
    url: "/admin-api/web/news/open/get",
    method: "GET",
    params,
  });
}
// 人才政策---ok
export function Hotword2(params) {
  return request({
    url: "/admin-api/web/talent-policy/page",
    method: "GET",
    params,
  });
}
// 人才政策详情---ok
export function Hotwordinfo2(params) {
  return request({
    url: "/admin-api/web/talent-policy/open/get",
    method: "GET",
    params,
  });
}

// ··············人才培养(数字工程师、继续教育、三项岗位人员、同等学历板块、课程专栏)
// 继续教育--ok
export function continuingeducation(params) {
  return request({
    url: "/admin-api/web/continuing-education/page",
    method: "GET",
    params,
  });
}
// 继续教育详情--ok
export function continuingeducationinfo(params) {
  return request({
    url: "/admin-api/web/continuing-education/get",
    method: "GET",
    params,
  });
}
// 获得附件---ok
export function attachment(params) {
  return request({
    url: "/admin-api/web/attachment/list",
    method: "GET",
    params,
  });
}
// 三项岗位-栏目简介--ok
export function postintroduction(params) {
  return request({
    url: "/admin-api/web/post-introduction/page",
    method: "GET",
    params,
  });
}
// 三项岗位-栏目简介-详情--ok
export function postintroductioninfo(params) {
  return request({
    url: "/admin-api/web/post-introduction/get",
    method: "GET",
    params,
  });
}
// 三项岗位-报名资料-资料类型--ok
export function subType(params) {
  return request({
    url: "/admin-api/web/post-subtype/page",
    method: "GET",
    params,
  });
}
// 三级分类
export function post_thirdtype(params) {
  return request({
    url: "/admin-api/web/post-thirdtype/page",
    method: "GET",
    params,
  });
}




// 三项岗位-报名资料--ok
export function postresource(params) {
  return request({
    url: "/admin-api/web/post-resource/page",
    method: "GET",
    params,
  });
}
// 课程专栏--ok
export function courselist(params) {
  return request({
    url: "/admin-api/web/course/page",
    method: "GET",
    params,
  });
}
// 同等学历板块-报名列表
export function enrolldetail(params) {
  return request({
    url: "/admin-api/web/enroll/page",
    method: "GET",
    params,
  });
}
// 同等学历板块-报名列表-详情(富文本)
export function enrolldetailinfo(params) {
  return request({
    url: "/admin-api/web/enroll/get",
    method: "GET",
    params,
  });
}
// 获取报名项目的报名记录
export function getRecordByEnrollId(params) {
  return request({
    url: "/admin-api/web/enroll-detail/getRecordByEnrollId",
    method: "GET",
    params,
  });
}
// 同等学历板块-保存
export function enrolldetailSubmit(data) {
  return request({
    url: "/admin-api/web/enroll-detail/create",
    method: "POST",
    data,
  });
}
// 同等学历-保存后获取附件
export function getAttach(params) {
  return request({
    url: "/admin-api/web/enroll-detail/getAttach",
    method: "GET",
    params,
  });
}
// 更新报名
export function enrolldetailSubmitupdate(data) {
  return request({
    url: "/admin-api/web/enroll-detail/update",
    method: "PUT",
    data,
  });
}

// ··············人才服务(人才政策、职称评审、技能认定、人才项目信息)
// 人才项目信息---ok
export function TalentService(params) {
  return request({
    url: "/admin-api/web/talent-policy/page",
    method: "GET",
    params,
  });
}
// 人才项目信息详情---ok
export function TalentServiceinfo(params) {
  return request({
    url: "/admin-api/web/talent-policy/open/get",
    method: "GET",
    params,
  });
}
// 职称评审-列表
export function titlereview(params) {
  return request({
    url: "/admin-api/web/title-review/page",
    method: "GET",
    params,
  });
}
// 职称评审-详情tab
export function titlereviewtab(params) {
  return request({
    url: "/admin-api/web/title-review-tab/page",
    method: "GET",
    params,
  });
}
// 职称评审-详情tab-详情
export function titlereviewtabinfo(params) {
  return request({
    url: "/admin-api/web/title-review-tab/get",
    method: "GET",
    params,
  });
}
// 职称评审-详情tab-详情-申报材料
export function material(params) {
  return request({
    url: "/admin-api/web/title-review-attach/page",
    method: "GET",
    params,
  });
}
// 获得自己的职称评审申报记录
export function record2(params) {
  return request({
    url: "/admin-api/web/title-review-record/getRecordByTitleReviewId",
    method: "GET",
    params,
  });
}
// 职称评审-立即申报-提交-保存
export function reviewSubmit(data) {
  return request({
    url: "/admin-api/web/title-review-record/create",
    method: "POST",
    data,
  });
}
// 更新申报记录
export function reviewSubmitupdate(data) {
  return request({
    url: "/admin-api/web/title-review-record/update",
    method: "PUT",
    data,
  });
}

// ··············办事窗口
// 操作指南---ok
export function operationguide(params) {
  return request({
    url: "/admin-api/web/operation-manual/page",
    method: "GET",
    params,
  });
}
// 操作指南-详情---ok
export function operationguideinfo(params) {
  return request({
    url: "/admin-api/web/operation-manual/get",
    method: "GET",
    params,
  });
}
// 办事指南---ok
export function workguide(params) {
  return request({
    url: "/admin-api/web/work-manual/page",
    method: "GET",
    params,
  });
}
// 办事指南-详情---ok
export function workguideinfo(params) {
  return request({
    url: "/admin-api/web/work-manual/get",
    method: "GET",
    params,
  });
}
// 职场指南---ok
export function Careerguide(params) {
  return request({
    url: "/admin-api/web/job-manual/page",
    method: "GET",
    params,
  });
}
// 职场指南-详情---ok
export function Careerguideinfo(params) {
  return request({
    url: "/admin-api/web/job-manual/get",
    method: "GET",
    params,
  });
}
// 下载专区---ok
export function download(params) {
  return request({
    url: "/admin-api/web/download-center/page",
    method: "GET",
    params,
  });
}
// 报名系统--ok
export function enroll(params) {
  return request({
    url: "/admin-api/web/enroll/page",
    method: "GET",
    params,
  });
}
// 获得三项岗位-banner
export function post_banner(params) {
  return request({
    url: "/admin-api/web/post-banner/page",
    method: "GET",
    params,
  });
}

